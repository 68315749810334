import React, { useEffect, useState } from "react";
import "./Section4.scss";
import sec4gif from "../../Assets/Videos/nomieat.gif";
import logo from "../../Assets/Images/logo.svg";

function Section4() {

  const [count, setCount] = useState(0);

  useEffect(() => {
    // This effect will run on mount (page load)
    const intervalId = setInterval(() => {
      setCount((prevCount) => {
        const newCount = prevCount + 1;

        return newCount <= 10000 ? newCount : prevCount;
      });
    }, 1);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []); // The empty dependency array ensures that this effect runs only on mount
  
  return (
    <div className="section4_cont">
      <div className="secion4_box">
        <div className="section4_box_left">
          <div className="d-block">
            <div className="section4_box_left_title">
              Our clients trust <img src={logo} className="header_logo" alt="logo" /> to manage & process 10+ petabytes of data and growing!
            </div>
          </div>
          <div className="section4_box_procc">
            <div className="section4_box_procc_title">Terabytes Processed</div>
            <div className="section4_box_procc_num">
              {Intl.NumberFormat('en-IN', { maximumSignificantDigits: 4 }).format(count)}
            </div>
          </div>
        </div>
        <div className="section4_box_right">
          <div className="section4_box_left_title">
            Meet Nomi AI
          </div>
          <div className="section4_box_procc2">
            <img src={sec4gif} alt="nomi" className="nomigif" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section4;
